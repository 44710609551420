.homepage-container {
  font-family: "Arial", sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.homepage-header {
  text-align: center;
  margin-bottom: 20px;
}

.search-bar {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.category-btn {
  padding: 8px 16px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.category-btn.active,
.category-btn:hover {
  background-color: #007bff;
  color: white;
}

.trending-section,
.articles-section {
  margin-top: 30px;
}

.article-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.article-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  background: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.article-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.read-more {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin-top: 10px;
}

.read-more:hover {
  text-decoration: underline;
}


.search-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .search-categories {
    flex-direction: row;
    justify-content: space-between;
  }

  .homepage-header {
    flex: 1;
    margin-right: 20px;
  }

  .categories-container {
    flex: 1;
    max-width: 200px;
  }
}

.homepage-header input{
  width: 80%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.styled-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}