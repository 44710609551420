.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-in-out;
}

.about-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    animation: slideInFromLeft 1s ease-in-out;
}

.about-description, .about-thankyou {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 15px;
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}