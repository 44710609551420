/* WriterDashboard.css */
.writer-dashboard {
    display: flex;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .dashboard-sidebar {
    width: 250px;
    background: #343a40;
    color: rgb(0, 0, 0);
    padding: 20px;
  }
  
  .sidebar-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .sidebar-menu li {
    list-style: none;
    padding: 10px 0;
    margin-top: 3px;
    cursor: pointer;
    border-radius: 10%;
    text-align: center;
    
    background-color: #fdfefe;
  }
  
  .dashboard-content {
    flex-grow: 1;
    background: white;
    padding: 30px;
  }
  
  .content-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .stats-card {
    width: 150px;
    border-radius: 8px;
    text-align: center;
  }
  
  .articles-section {
    margin-top: 20px;
  }
  
  .articles-list {
    padding: 0;
    list-style: none;
  }
  
  .article-item {
    padding: 10px;
    background: #f8f9fa;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .btn-primary {
    font-size: 16px;
  }
  


  .rich-text-editor {
    height: 300px;
    background: white;
  }
  
  .writing-section {
    margin-top: 20px;
    height: 97vh;
    overflow-y: auto;
  }
  
  .btn-primary, .btn-success {
    font-size: 16px;
    padding: 10px 20px;
  }
  