.articles-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.articles-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.article-card {
  border: 1px solid #aaa7a7;
  border-radius: 8px;
  background-color: #fae0e0;
  transition: box-shadow 0.3s ease;
  flex: 1 1 calc(33.333% - 40px); /* Adjust the width of the card */
  box-sizing: border-box;
  margin-bottom: 20px;
}

.article-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-card h5 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.article-card p {
  margin: 0;
}

.article-card .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.article-card .d-flex {
  display: flex;
  align-items: center;
}

.article-card .justify-content-between {
  justify-content: space-between;
}

.article-card .mr-2 {
  margin-right: 0.5rem;
}

.article-card .btn {
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.article-card .btn-primary {
  background-color: #3498db;
  color: white;
  border: none;
}

.article-card .btn-danger {
  background-color: #e74c3c;
  color: white;
  border: none;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .article-card {
    flex: 1 1 calc(50% - 40px); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .article-card {
    flex: 1 1 100%; /* 1 card per row */
  }
}
