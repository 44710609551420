.article-page {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .article-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .article-content {
    margin-top: 15px;
    line-height: 1.6;
  }
  
  .back-btn {
    background: #0073e6;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .back-btn:hover {
    background: #005bb5;
  }

  .article-title{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .article-page {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 14px;
    color: #555;
  }
  
  .article-meta span {
    background: #f3f3f3;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .article-content {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 15px;
  }
  
  .article-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
 
  .like-btn, .next-btn, .view-btn {
    background: #0279a8;
    color: #fff;
    border: none;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .like-btn:hover, .next-btn:hover {
    opacity: 0.8;
  }
  .like-btn{
    margin-left: 30%;
  }
  .view-btn{
    margin-right: 30%;
  }
  .next-btn{
    margin-right: 0%;
  }

  .view-btn:hover{
    cursor: pointer; 
  }
  
  .social-share {
    margin-top: 20px;
    text-align: center;
  }
  
  .social-share a {
    margin-right: 10px;
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
  }
  
  .comments-section {
    margin-top: 20px;
  }
  
  .comments-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .comments-section textarea {
    width: 100%;
    height: 80px;
    margin-top: 10px;
  }
  
  .comments-section button {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .comment-card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  .comment-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
  }

  .comment-header strong {
    font-weight: bold;
  }

  .comment-header span {
    font-size: 12px;
    color: #777;
  }

  @media (max-width: 600px) {
    .article-actions {
      flex-direction: column;
      align-items: center;
    }

    .like-btn, .next-btn, .view-btn {
      width: 100%;
      margin: 5px 0;
    }

    .like-btn {
      margin-left: 0;
    }

    .view-btn {
      margin-right: 0;
    }
  }