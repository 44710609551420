.site-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img {
    height: 50px;
    margin-right: 10px;
}

.header-logo h1 {
    font-size: 24px;
    margin: 0;
}

nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin-left: 20px;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

nav ul li a:hover {
    text-decoration: underline;
}
.userprofile {
    margin-right: 8px;
    vertical-align: middle;
}
nav ul li.dropdown {
    position: relative;
}
.menu-toggle{
    display: none;
}
nav ul li .dropdown-content {
    display: none;
    position: absolute;
    background-color: #333;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

nav ul li .dropdown-content a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

nav ul li .dropdown-content a:hover {
    background-color: #575757;
}

nav ul li.dropdown:hover .dropdown-content {
    display: block;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .site-header {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .header-logo img {
        height: 40px;
    }

    .header-logo h1 {
        font-size: 20px;
    }

    nav ul li a {
        font-size: 16px;
    }

    .menu-toggle {
        background: none;
        border: none;
        color: #fff;
        font-size: 18px;
        display: flex;
        cursor: pointer;
        position: absolute;
        top: 17px;
        right: 17%;
    }

    nav {
        display: none;
    }

    nav.open {
        margin-top: 17px;
        display: block;
    }
}