.p-3.text-center.flex-grow-1 {
    background: #ffffff; /* White background */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
    padding: 20px; /* Spacing inside the card */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth hover effect */
}

.p-3.text-center.flex-grow-1:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.stat-icon {
    color: #007bff; /* Primary color */
}

h5 {
    margin-top: 10px;
    color: #333; /* Darker text for better readability */
    font-weight: 600;
}

.fs-4.fw-bold {
    color: #222; /* Slightly darker for contrast */
}
