/* Register.css */
.register-form {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
}

.form-input,
.form-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.form-input:focus,
.form-select:focus {
    border-color: #0056b3;
    outline: none;
}

.form-button {
    width: 100%;
    padding: 12px;
    background-color: #0056b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
}

.form-button:hover {
    background-color: #004494;
}

@media (max-width: 600px) {
    .register-form {
        padding: 20px;
    }

    .form-label {
        font-size: 14px;
    }

    .form-input,
    .form-select {
        padding: 10px;
    }

    .form-button {
        padding: 10px;
        font-size: 16px;
    }
}